import { useStaticQuery, graphql } from 'gatsby';

export default function allMegaMenuBlocks() {
  const { megaMenuBlock } = useStaticQuery(graphql`
    query megaMenuBlockQuery {
      megaMenuBlock: allBlockContentMegamenuBlock {
        nodes {
          title: field_block_title
          subtitle: field_block_subtitle
          link: field_block_link {
            title
            uri
          }
          relationships {
            menuLink: field_menu_link {
              title
            }
            image: field_block_image {
              name
              attributes: field_media_image {
                alt
                title
              }
            }
          }
        }
      }
    }
  `);
  return megaMenuBlock.nodes || [];
}
