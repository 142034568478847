import useMedia from 'use-media';

export function useMobile() {
  return useMedia({ maxWidth: '768px' });
}

export function useDesktop() {
  return useMedia({ minWidth: '1024px' });
}

export function useMobileL() {
  return useMedia({ maxWidth: '429px' });
}

export function useTablet() {
  return useMedia({ maxWidth: '1024px' });
}
