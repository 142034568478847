import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import truncate from '@core/helpers/truncate';
import DomPurify from '@core/helpers/dom-purify';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function WhitepaperLinkItem({
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernWhitepaperLinkItem menuLink={menuLink} />
  ) : (
    <DefaultWhitepaperLinkItem menuLink={menuLink} />
  );
}

function ModernWhitepaperLinkItem({ menuLink }: { menuLink: MenuLink }): ReactElement {
  const { t, i18n } = useTranslation();
  const result = WhitepaperQuery();
  const items = translateContent(result.whitepapers?.edges ?? [], i18n.language);

  return (
    <div className="d-flex flex-column mt-3">
      {items.slice(0, 3).map(({ node }, index) => (
        <div className="d-flex flex-column mb-3" key={node?.id}>
          {index === 0 && (
            <GatsbyImageProvider
              nodeImage={node?.relationships?.image}
              altImage={node?.relationships?.image?.attributes?.alt || ''}
              className="w-100 mb-2 resources-menu-image object-fit-cover"
              enableGatsbyImage={false}
            />
          )}
          <GatsbyLink to={node?.link?.uri || ''} className="card-link text-marine">
            <h6 className={`m-0 pb-2 ${index === 0 ? 'mt-1 border-bottom' : ''}`}>{node?.title}</h6>
          </GatsbyLink>
        </div>
      ))}
    </div>
  );
}

function DefaultWhitepaperLinkItem({ menuLink }: { menuLink: MenuLink }): ReactElement {
  const { t, i18n } = useTranslation();
  const result = WhitepaperQuery();
  const items = translateContent(result.whitepapers?.edges ?? [], i18n.language);

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">{t('Mega Menu Whitepapers overview')}</p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.slice(0, 6).map(({ node }) => (
              <div className="col-6 mb-3" key={node?.id}>
                <div className="row">
                  <div className="col-4 px-0">
                    <GatsbyImageProvider
                      nodeImage={node?.relationships?.image}
                      altImage={node?.relationships?.image?.attributes?.alt || ''}
                      className="rounded-lg-top solution-thumbnail w-100 object-fit-cover position-relative"
                      enableGatsbyImage={false}
                    />
                  </div>
                  <div className="col">
                    <GatsbyLink to={node?.link?.uri || ''} className="card-link text-dark-indigo">
                      <DomPurify text={truncate(node?.title as string, 50)} />
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink className="small font-weight-bold text-cool-blue text-sm-left pl-3" to={menuLink?.link?.alias || ''}>
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function WhitepaperQuery() {
  const nodes = useStaticQuery(graphql`
  query WhitepaperQuery {
    whitepapers: allNodeWhitepaper(limit: 6, filter: {field_weight: {gte: 0}, field_featured: {eq: false}}, sort: {fields: field_weight, order: DESC}) {
      edges {
        node {
          id
          title
          langcode
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name

              attributes: field_media_image {
                alt
                title
              }
            }
          }
        }
      }
    }
  }
  `);

  return nodes;
}
