import { useStaticQuery, graphql } from 'gatsby';

export default function allDataFooter() {
  const nodes = useStaticQuery(graphql`
    query allDataFooter {
      mainMenu: allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: "footer"}}) {
        nodes {
          menuName: menu_name
          drupalId: drupal_id
          drupalParentMenuItem: drupal_parent_menu_item
          title
          langcode
          enabled
          weight
          link {
            alias: uri_alias
            uri
          }
        }
      }
      offices: allNodeOffice(sort: {order: ASC, fields: field_weight}) {
        edges {
          node {
            id
            title
            langcode
            body {
              processed
            }
            phone: field_phone
            field_weight
          }
        }
      }
    }
  `);

  return nodes;
}
