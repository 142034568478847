import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Graphql } from '@models/graphql';

let allFiles = [] as Graphql[];

type ImageProviderProps = {
  fileName?: string;
  className?: string;
  alt?: string;
  title?: string;
  width?: string|undefined;
  height?: string|undefined;
  fluid?: boolean;
  lazy?: boolean;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
};

ImageProvider.defaultProps = {
  className: '',
  alt: '',
  title: '',
  fileName: '',
  width: undefined,
  height: undefined,
  fluid: true,
  lazy: true,
  onClick: undefined
};

export default function ImageProvider({
  className, fileName, alt, title, width, height, fluid = true, lazy = true, onClick
}: ImageProviderProps) {
  if (!fileName) {
    return null;
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (urlRegex.test(fileName)) {
    return (
      <img
        className={className}
        src={fileName}
        alt={alt}
        title={title || alt}
        width={width}
        height={height}
        loading={lazy ? 'lazy' : 'eager'}
        onClick={onClick}
      />
    );
  }

  if (allFiles.length <= 0) {
    const { allFile } = useStaticQuery(graphql`
      query {
        allFile(
          filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)|(gif)/" } }
        ) {
          edges {
            node {
              base
              extension
              publicURL
              id
            }
          }
        }
      }
    `);

    if (allFile && allFile.edges) {
      allFiles = allFile.edges;
    }
  }

  const file = allFiles.find((edge) => {
    const { base } = edge?.node || {};
    return base === encodeURIComponent(fileName) || decodeURIComponent(`${base}`) === fileName;
  });

  if (!file || !file.node) {
    return null;
  }

  const { node } = file;

  return (
    <img
      className={`${className} ${fluid ? 'w-100' : ''}`}
      src={`${node.publicURL}`}
      alt={alt}
      title={title || alt}
      width={width}
      height={height}
      loading={lazy ? 'lazy' : 'eager'}
      onClick={onClick}
    />
  );
}
