import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Navbar, Nav, NavDropdown, ListGroup } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faPlay,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ContentComponent from '@core/layout/navigation-bar/mega-menu/content-helpers';
import { useSearch } from '@core/providers/search/search-provider';
import ImageProvider from '@core/providers/image/image-provider';
import SearchAutocomplete from '@core/layout/navigation-bar/mega-menu/search-autocomplete';
import { getPathFromInternalUrl } from '@core/helpers/link';

import { MenuLink } from '@models/menu-link';
import { LocationModel } from '@models/location';

import './desktop-menu.scss';
import { Graphql } from '@shared/models/graphql';

type DesktopMenuProps = {
  menu: MenuLink[];
  location: LocationModel;
  megaMenuStyle?: string;
  megaMenuBlocks?: Graphql[];
};

export default function ModernDesktopMenu({
  menu,
  location,
  megaMenuStyle,
  megaMenuBlocks
}: DesktopMenuProps): React.ReactElement {
  const [contentMenu, setContentMenu] = useState<MenuLink | null>(null);
  const [indexActive, setIndexActive] = useState<number>(-1);
  const { t } = useTranslation();

  const getMenuLink = (link: MenuLink, index: number) => {
    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault();
      if (indexActive === index) {
        setIndexActive(-1);
        setContentMenu(null);
      } else {
        setIndexActive(index);
        if (link.children && link.children.length > 0) {
          setContentMenu(link.children[0]);
        } else {
          setContentMenu(null);
        }
      }
    };

    const matchingBlock = megaMenuBlocks?.find(
      (block) => block.relationships?.menuLink?.title === link?.title
    );

    if (link.children && link.children.length <= 0) {
      return (
        <GatsbyLink
          key={`${link.drupalId}`}
          className={`nav-link nav-item small ml-2 d-flex align-items-center ${
            indexActive === index ? 'active' : ''
          }`}
          to={link?.link?.alias || ''}
        >
          {link.title}
        </GatsbyLink>
      );
    }

    return (
      <NavDropdown
        onClick={handleClick}
        className={`nav-item dropdown small ml-2 d-flex align-items-center mega-menu-modern modern-nav-item ${
          indexActive === index ? 'active' : ''
        } ${indexActive} ${index}`}
        key={`${link.drupalId}`}
        title={link.title}
        id={`dropdown-${link.drupalId}`}
        show={indexActive === index}
      >
        <div className="container h-100">
          <div className="row my-4 w-100 h-100">
            {link.title !== t('Services') &&
            link.title !== t('Solutions') &&
            link.title !== t('Resources') ? (
              <>
                <div className="col-3 pr-5">
                  <h6 className="mega-menu-title mb-3">{link.title}</h6>
                  <ListGroup variant="flush" className="mega-menu-group">
                    {link?.children?.map((child) => (
                      <div
                        key={`link-${child.title}`}
                        className={`mega-menu-link position-relative ${
                          contentMenu && contentMenu.drupalId === child.drupalId
                            ? 'active'
                            : ''
                        }`}
                        onMouseEnter={() => setContentMenu(child)}
                      >
                        <GatsbyLink
                          className="nav-link small ml-2 text-sm-left text-marine"
                          to={child?.link?.alias || ''}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIndexActive(-1);
                            setContentMenu(null);
                          }}
                        >
                          {child.title}
                        </GatsbyLink>
                        {contentMenu &&
                          contentMenu.drupalId === child.drupalId && (
                            <div className="position-absolute mega-menu-link-arrow d-flex align-items-center">
                              <FontAwesomeIcon icon={faPlay as IconProp} />
                            </div>
                          )}
                      </div>
                    ))}
                  </ListGroup>
                  <div className="position-absolute mega-menu-divider" />
                </div>
                <div className="col-9">
                  {contentMenu && (
                    <ContentComponent
                      machineName={contentMenu?.title || ''}
                      menuLink={contentMenu}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="col-12 w-100 d-flex flex-column menu-table">
                <div className="d-flex justify-content-start flex-grow-1">
                  {link?.children?.map(
                    (child, index, array) =>
                      child.title &&
                      !child.title
                        .toLowerCase()
                        .includes(t('See All').toLowerCase()) && (
                        <div
                          key={`link-${child.title}`}
                          className={`d-flex menu-table-cell ${
                            !matchingBlock && index === array.length - 1
                              ? 'background-soft-grey-white'
                              : ''
                          }`}
                        >
                          <div
                            className={`h-100 d-flex flex-column w-100 ${
                              (matchingBlock && index === array.length - 1) ||
                              (!matchingBlock && index >= array.length - 2)
                                ? ''
                                : 'border-right'
                            }`}
                          >
                            <div className="w-100 background-white">
                              <h5 className="text-marine px-3">
                                {child.title}
                              </h5>
                            </div>
                            <div className="px-3">
                              <ContentComponent
                                machineName={child.title || ''}
                                menuLink={child}
                                megaMenuStyle={megaMenuStyle}
                              />
                            </div>
                            <GatsbyLink
                              className="text-base mt-auto mb-1 px-3"
                              to={child?.link?.alias || ''}
                            >
                              {t('See All')}
                            </GatsbyLink>
                          </div>
                        </div>
                      )
                  )}
                  {matchingBlock && (
                    <div className="d-flex menu-table-cell background-soft-grey-white">
                      <div className="px-3 pt-3 h-100 w-100 d-flex flex-column">
                        {matchingBlock?.relationships?.image && (
                          <ImageProvider
                            className="pt-1 pb-2 block-menu-image"
                            fileName={matchingBlock?.relationships?.image?.name}
                            alt={
                              matchingBlock?.relationships?.image?.attributes
                                ?.alt
                            }
                          />
                        )}
                        {matchingBlock.title && (
                          <h5 className="text-marine mt-2">
                            {matchingBlock.title}
                          </h5>
                        )}
                        {matchingBlock.subtitle && (
                          <p className="text-emperor-grey mt-2">
                            {matchingBlock.subtitle}
                          </p>
                        )}
                        {matchingBlock.link && matchingBlock.link.uri && (
                          <GatsbyLink
                            className="text-base mt-auto mb-1"
                            to={getPathFromInternalUrl(matchingBlock.link.uri)}
                          >
                            {matchingBlock.link.title}
                          </GatsbyLink>
                        )}
                      </div>
                    </div>
                  )}{' '}
                </div>
                {link?.children?.some((child) =>
                  child.title
                    ?.toLowerCase()
                    .includes(t('See All').toLowerCase())
                ) && (
                  <div className="d-flex justify-content-center border-top">
                    <h5 className="text-marine mt-3">
                      {t('Would you like to learn more about our services?')}
                    </h5>
                    {link?.children?.map(
                      (child) =>
                        child.title &&
                        child.title
                          .toLowerCase()
                          .includes(t('See All').toLowerCase()) && (
                          <div key={`link-${child.title}`}>
                            <GatsbyLink
                              className="mt-auto mb-1"
                              to={child?.link?.alias || ''}
                            >
                              <h5 className="text-tangerine ml-2 mt-3">
                                {child.title}{' '}
                                <FontAwesomeIcon
                                  icon={faArrowRight as IconProp}
                                  size="xs"
                                />
                              </h5>
                            </GatsbyLink>
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </NavDropdown>
    );
  };

  if (menu.length <= 0) {
    return <></>;
  }

  return (
    <div className="desktop-menu d-flex align-items-center position-relative">
      <Navbar className="ml-auto navbar-menu">
        <Nav>{menu.map(getMenuLink)}</Nav>
      </Navbar>
      <SearchBar />
    </div>
  );
}

function SearchBar(): React.ReactElement {
  const { showSearch, setShowSearch } = useSearch();

  return (
    <>
      <span className="search-icon cursor-pointer">
        <FontAwesomeIcon
          icon={(showSearch ? faTimes : faSearch) as IconProp}
          onClick={() => setShowSearch(!showSearch)}
        />
      </span>
      {showSearch && <SearchAutocomplete limit={8} />}
    </>
  );
}
