import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink
};

export default function ReviewsLinkItem({ machineName }: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <div className="row">
        <div className="col-7">
          <div className="row mx-0">
            <p className="font-weight-normal text-secondary">
              {t(`Mega Menu ${machineName} overview`)}
            </p>
          </div>
        </div>
        <div className="col-5">
          <div className="row mx-0">
            <ImageProvider
              className="d-none d-lg-block ml-2"
              fluid
              fileName="clutch.png"
              alt="logo"
              lazy={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
