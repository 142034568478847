import React, { useRef, useEffect } from 'react';

import NavigationBarContact from '@core/layout/navigation-bar/contact/contact-bar';
import TopBar from '@core/layout/top-bar/top-bar';
import MegaMenu from '@core/layout/navigation-bar/mega-menu/mega-menu';

import { LocationModel } from '@models/location';
import { Graphql } from '@models/graphql';

const { onlyLogo, onlyContact } = require('@config/styles');

type HeaderProps = {
  node: Graphql;
  location: LocationModel;
};

let currentHeaderHeight = 0;

export function getHeaderHeight(): number {
  return currentHeaderHeight;
}

export default function Header({
  node,
  location
}: HeaderProps): React.ReactElement {
  const { headerStyle } = node || {};
  const isOnlyContact = headerStyle === onlyContact;
  const isNavigationLinkDisabled = headerStyle === onlyLogo;
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        currentHeaderHeight = headerRef.current.offsetHeight;
      }
    };

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const headerContent = isOnlyContact ? (
    <NavigationBarContact />
  ) : (
    <div className="sticky shadow-sm" ref={headerRef}>
      <TopBar node={node} />
      <MegaMenu
        node={node}
        location={location}
        navigationDisabled={isNavigationLinkDisabled}
      />
    </div>
  );

  return headerContent;
}
