import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { Pagination } from '@models/pagination';
import { Graphql } from '@models/graphql';
import { Translation } from '@models/translation';
import { reactEnFaq, reactEsFaq, search } from './snippets';

const { hreflang } = require('@config/hreflang');

type SearchEngineOptimizationProps = {
  node: Graphql;
  pagination: Pagination|undefined;
  type?: string;
}

SearchEngineOptimization.defaultProps = {
  type: ''
};

export default function SearchEngineOptimization({
  node, pagination, type
}: SearchEngineOptimizationProps) {
  if (!node) {
    return null;
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const { currentPage } = pagination || { currentPage: 0 };

  let title = node ? node.title?.slice(0, 60) : 'Rootstack';
  let metaDescription = (node && node.body && node.body.summary) ? node.body.summary : title;
  let absolutePath = `${process.env.GATSBY_DEFAULT_URL}${node?.path?.alias}`;

  if (currentPage > 1) {
    title += ` page ${pagination?.currentPage}`;
    metaDescription += ` (page ${pagination?.currentPage})`;
    absolutePath += `/page/${pagination?.currentPage}`;
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata?.author || '',
    },
    {
      name: 'author',
      content: site.siteMetadata?.author || '',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      name: 'googlebot',
      content: node?.robots || 'all',
    },
    {
      name: 'publisher',
      content: site.siteMetadata?.author || '',
    },
    {
      name: 'keywords',
      content: node?.keywords || '',
    },
  ];

  let links: { rel: string, href: string, hreflang?: string }[] = [];

  links = links.concat([
    { rel: 'canonical', href: absolutePath }
  ]);

  if (node.langcode) {
    links = links.concat([
      { rel: 'alternate', hreflang: `${hreflang[node.langcode]}`, href: `${absolutePath}` },
      { rel: 'alternate', hreflang: 'x-default', href: `${process.env.GATSBY_DEFAULT_URL}` }
    ]);
  }

  const translations = (node.relationships?.translations || []).map(
    (translation: Translation) => {
      const lang = hreflang[translation.langcode];
      let href = '';

      if (translation.path.alias) {
        href = `${process.env.GATSBY_DEFAULT_URL}${translation.path.alias}`;

        if (currentPage > 1) {
          href += `/page/${pagination?.currentPage}`;
        }
      }

      return {
        rel: 'alternate',
        hreflang: lang,
        href
      };
    }
  ).filter((item) => item.href !== '');

  links = links.concat(translations);

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: node ? node.langcode : '' }}
        meta={metaTags}
        link={links}
        title={`${title} | Rootstack`}
      >
        <title>{`${title} | Rootstack`}</title>
        {type === 'search' && (
          search(node?.langcode || '')
        )}
        {node?.path?.alias === '/en/blog/react-vs-react-native' && (
          reactEnFaq()
        )}
        {node?.path?.alias === '/es/blog/react-vs-react-native' && (
          reactEsFaq()
        )}
      </Helmet>
    </>
  );
}
