import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { isBrowser } from '@core/helpers/ssr';
import { useSearch } from '@core/providers/search/search-provider';

import { Graphql } from '@models/graphql';
import { EventTarget } from '@models/event-target';

type ItemResultProps = {
  title: string,
  onClick: () => void,
  viewAll: boolean
}

function ItemResultAutocomplete({ title, onClick, viewAll }: ItemResultProps): ReactElement {
  return (
    <div className="d-flex align-items-center item-box" onClick={onClick} role="button" aria-hidden="true">
      <div className="p-1 d-flex">
        <span className={`search-icon p-2 d-flex justify-content-center align-items-center ${viewAll ? 'view-all' : ''}`}>
          <FontAwesomeIcon
            icon={faSearch as IconProp}
          />
        </span>
      </div>
      <div className="p-1">{title}</div>
    </div>
  );
}

type SearchAutocompleteProps = {
  limit: number
};

export default function SearchAutocomplete({ limit }: SearchAutocompleteProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { showSearch, setShowSearch, handleTextSearch } = useSearch();
  const [searchResult, setSearchResult] = useState([]);

  const handleChange = (event: EventTarget) => {
    setSearchTerm(event.target.value);
    const result = handleTextSearch(i18n.language, event.target.value);
    setSearchResult(result);
  };

  const handleOpenResult = (link: string) => {
    if (isBrowser) {
      if (link) {
        window.location.href = link;
      } else {
        window.location.href = `${t('Path search page')}?text=${searchTerm}`;
      }
    }
  };

  const handleKeyDown = (event: EventTarget) => {
    if (isBrowser && event.key === 'Enter' && searchTerm) {
      window.location.href = `${t('Path search page')}?text=${searchTerm}`;
    }
  };
  return (
    <div className="position-absolute w-100 search-container">
      <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 flex-column bg-white search-box">
        <div className="d-flex align-items-center w-100">
          <input
            type="text"
            className="form-control search-input ml-2 focus"
            placeholder={`${t('Search')}...`}
            value={searchTerm}
            onKeyDown={(event) => handleKeyDown(event as unknown as EventTarget)}
            onChange={(event) => handleChange(event as unknown as EventTarget)}
          />
          <span className="cursor-pointer ml-4">
            <FontAwesomeIcon
              icon={(showSearch ? faTimes : faSearch) as IconProp}
              onClick={() => setShowSearch(!showSearch)}
            />
          </span>
        </div>
        {searchResult && searchResult.length > 0 && (
          <div className="search-autocomplete w-100 bg-white">
            {searchResult.slice(0, limit).map((item: Graphql) => (
              <ItemResultAutocomplete
                title={`${item.title}`}
                key={item.id}
                onClick={() => {
                  handleOpenResult(item?.link?.uri || '');
                }}
                viewAll={false}
              />
            ))}
            {
              searchResult.length > 0 && (
                <ItemResultAutocomplete viewAll title={t('View all results')} onClick={() => handleOpenResult(`${t('Path search page')}?text=${searchTerm}`)} />
              )
            }
          </div>
        )}
      </div>
    </div>
  );
}
