import React, { useEffect, useState } from 'react';

import { Country } from '@models/country';

import { defaultContext, ipApiBaseUrl } from './config';

export const CountryContext = React.createContext(defaultContext);

type CountryProviderProps = {
  children: React.ReactElement[];
};

export default function CountryProvider({ children }: CountryProviderProps): React.ReactElement {
  const [country, setCountry] = useState({} as Country);

  useEffect(() => {
    fetch(ipApiBaseUrl)
      .then((response) => response.json())
      .then((result) => setCountry(result as Country))
      .catch(() => setCountry({ country: 'Panama', countryCode: 'PA' } as Country));
  }, []);

  return (
    <CountryContext.Provider value={country}>
      {children}
    </CountryContext.Provider>
  );
}
