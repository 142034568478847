import React from 'react';

export const search = (langcode: string) => (
  <script type="application/ld+json">
    {`
    {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "${process.env.GATSBY_DEFAULT_URL}/${langcode}/",
        "potentialAction": {
        "@type": "SearchAction",
        "target": {
            "@type": "EntryPoint",
            "urlTemplate": "${process.env.GATSBY_DEFAULT_URL}/${langcode}/search/?text={search_term_string}"
        },
        "query-input": "required name=search_term_string"
        }
    }
    `}
  </script>
);

export const reactEnFaq = () => (
  <script type="application/ld+json">
    {`
    {
        "@context": "https://schema.org/",
        "@type": "FAQPage",
        "mainEntity": [
        {
            "@type": "Question",
            "name": "What is an ESB (Enterprise Service Bus)?  ",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "An Enterprise Service Bus is simply a software architecture integrated by a numerous set of rules and principles that can integrate a series of different applications in a single infrastructure."
            }
        },
        {
            "@type": "Question",
            "name": "Why use an Enterprise Service Bus (ESB)?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Companies mostly use an ESB to streamline business processes, implementing this tool as the backbone of their technology infrastructure. An ESB unites all the existing systems in a company, providing accessibility and scalability."
            }
        },
        {
            "@type": "Question",
            "name": "What are the benefits of Service Oriented Architecture (SOA)?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "SOA is a software architecture used for building business applications that are focused on service-based development. Its advantages include increased operational efficiency, aligning IT and business area operations, employing best practice methodology, easy maintenance, and a scalable platform."
            }
        },
        {
            "@type": "Question",
            "name": "ESB vs SOA: Which one is the best architecture?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "SOA focuses on building business applications that can be easily leveled and allow companies to grow, while enterprise service buses are just a part of the strategy to do that."
            }
        }
        ]
    }
    `}
  </script>
);

export const reactEsFaq = () => (
  <script type="application/ld+json">
    {`
    {
        "@context": "https://schema.org/",
        "@type": "FAQPage",
        "mainEntity": [
        {
            "@type": "Question",
            "name": "ReactJS Vs React Native: ¿Cuál es mejor?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Si estás pensando en crear una aplicación de software web simple, ReactJS es la tecnología perfecta. Por otro lado, si deseas crear aplicaciones móviles nativas reales que funcionen tan bien como cualquier otra aplicación móvil desarrollada con un SDK, React Native es la mejor opción disponible."
            }
        },
        {
            "@type": "Question",
            "name": "¿Para qué sirve React Native?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Es una herramienta que permite la creación de aplicaciones nativas reales exactamente igual a cualquier otra aplicación hecha con un SDK. React Native permite a los desarrolladores de software reutilizar componentes anteriores para acelerar el proceso de desarrollo."
            }
        },
        {
            "@type": "Question",
            "name": "¿Por qué usar React?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Con ReactJS puede realizar pequeños cambios en la estructura secundaria de su aplicación sin que dichos cambios afecten a los padres y al resto de la estructura. Esto permite una codificación estable y un rendimiento continuo."
            }
        },
        {
            "@type": "Question",
            "name": "¿Qué es React y para qué sirve?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Se trata de una biblioteca Javascript de código abierto que ayuda a crear interfaces de usuario interactivas de forma sencilla. React es bien conocido entre los desarrolladores de software por dos razones: una, es una interfaz de usuario confiable desarrollada por Facebook, y dos, permite a los ingenieros de software crear aplicaciones web y móviles."
            }
        }
        ]
    }
    `}
  </script>
);
