import React, { useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import translateContent from '@core/helpers/translation';
import DomPurify from '@core/helpers/dom-purify';

import { BannerBottomModel } from '@shared/models/banner-bottom-model';

import { Graphql } from '@models/graphql';

import allDataFloatBanner from './default-query';
import './banner-bottom.scss';

type BannerProps = {
  node: Graphql;
  language: string;
};

export default function BannerBottom({
  node,
  language
}: BannerProps): React.ReactElement {
  const data = allDataFloatBanner();

  const type = node?.type || 'node__landing_page';
  const cleanType = type.replace('node__', '');

  const banners = translateContent(data?.banners?.edges ?? [], language).map(
    (banner: Graphql) => banner.node
  ) as BannerBottomModel[];

  const banner = banners.filter((banner) => banner.content_type.includes(cleanType))

  if (banner.length > 0) {
    return <Banner banner={banner[0]} />;
  } else {
    return <></>;
  }
}

function Banner({
  banner
}: {
  banner: BannerBottomModel;
}): React.ReactElement {
  const [isClosed, setIsClosed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    setIsClosed(!isClosed);
  };

  if (isClosed) {
    return <></>;
  }

  return (
    <section
      className="container-banner-bottom fixed-bottom bg-white mb-md-5 ml-md-5 align-middle rounded shadow"
      data-closable="slide-out-up"
    >
      <div className="banner-content">
        <div id="inner-content">
          <div
            className={`rounded d-flex align-items-center title-content py-2 px-3 p-lg-3 ${banner.bgcolor}`}
          >
            <h5 className="mb-0 text-white w-100 cursor-pointer" onClick={handleExpand}>{banner.title}</h5>
            <div className="d-flex text-white ml-auto">
              <div className="px-3 cursor-pointer" onClick={handleExpand}>
                {isExpanded ? (
                  <FontAwesomeIcon
                    size="lg"
                    icon={faChevronDown as IconProp}
                  />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp as IconProp} />
                )}
              </div>
              <div role="button" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </div>
            </div>
          </div>
          {isExpanded && (
            <div
              className={`bg-white rounded col-12 p-3 ${banner.text_alignment}`}
            >
              {banner.body?.processed && (
                <DomPurify text={banner.body.processed} />
              )}
              {banner.cta && (<>
                {
                  banner.cta.alias ? (
                  <a className="btn background-tangerine border-box text-white mt-4 mb-3 mx-2 px-2 float-right" href={banner.cta?.alias} target="_blank" > {banner.cta.title} </a>
                  ) : (
                  <a className="btn background-tangerine border-box text-white mt-4 mb-3 mx-2 px-2 float-right" href={banner.cta.uri} target="_blank" > {banner.cta.title} </a>
                  )} </>
                )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
