import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';
import DomPurify from '@core/helpers/dom-purify';

type LinkItemProps = {
  machineName: string;
};

export default function HowItWorks({
  machineName,
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="nav-item py-4">
      <p className="font-weight-normal text-secondary">
        {t(`How it works Overview ${machineName}`)}
      </p>
      <div className="row">
        <div className="col-7">
          <h6 className="mega-menu-title mb-3">{t('How it works')}</h6>
          <div className="row">
            <div className="col-6 font-weight-normal block-one text-secondary">
              {t(`How it works Block One ${machineName}`)}
            </div>
            <div className="col-6 font-weight-normal block-two text-secondary">
              {t(`How it works Block Two ${machineName}`)}
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="d-flex flex-column text-center bg-white h-100">
            <h5>{t('What our clients say about us')}</h5>
            <div className="d-flex align-items-center justify-content-center w-100">
              <div className="ml-4 w-25">
                <ImageProvider
                  fileName="quotes1.png"
                  className="pb-2"
                  fluid={false}
                  width="50%"
                  height="50%"
                />
              </div>
              <div className="mx-2 w-50">
                <ImageProvider
                  fileName="healthcare.png"
                  className="pb-2"
                  fluid={false}
                  width="50%"
                  height="50%"
                />
              </div>
              <div className="mr-4 w-25">
                <ImageProvider
                  fileName="quotes2.png"
                  className="pb-2"
                  fluid={false}
                  width="50%"
                  height="50%"
                />
              </div>
            </div>
            <div>
              <DomPurify
                text={t(
                  `How it works Testimony ${machineName}`
                )}
              />
            </div>
            <div className="mt-auto">
              <h6 className="text-dark-indigo m-0">{t(`How it works Testimony Company ${machineName}`)}</h6>
              <small className="text-orange small text-uppercase font-weight-bold">
              {t(`How it works Testimony Role ${machineName}`)}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
