import React, {
  createContext, useContext, useState, useMemo
} from 'react';
import { Graphql } from '@shared/models/graphql';

const IndexesContext = createContext<{
  indexes: Graphql[];
  setIndexes: React.Dispatch<React.SetStateAction<Graphql[]>>;
}>({
  indexes: [],
  setIndexes: () => {}
});

export function IndexesProvider({ children }: IndexProps) {
  const [indexes, setIndexes] = useState<Graphql[]>([]);

  const contextValue = useMemo(
    () => ({ indexes, setIndexes }),
    [indexes, setIndexes]
  );

  return (
    <IndexesContext.Provider value={contextValue}>
      {children}
    </IndexesContext.Provider>
  );
}

export const useIndexes = () => useContext(IndexesContext);

type IndexProps = {
  children: React.ReactNode;
};
