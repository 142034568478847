import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import ImageProvider from '@core/providers/image/image-provider';
import DomPurify from '@core/helpers/dom-purify';

import { MenuLink } from '@models/menu-link';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
};

export default function MethodologyLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title mb-4">{t('Some Technologies Used')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center w-100 px-0">
            <div className="w-25 mx-4">
              <ImageProvider
                fluid
                fileName="methodology.png"
                height="120"
              />
            </div>
            <div className="font-weight-normal text-secondary">
              <DomPurify text={t('Some Technologies Used Copy')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
