import React, { useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Slider from 'react-slick';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { useMobileL } from '@core/helpers/responsive';
import ImageProvider from '@core/providers/image/image-provider';
import translateContent from '@core/helpers/translation';
import buildMenu from '@core/helpers/menu';
import DomPurify from '@core/helpers/dom-purify';
import { removeTrailingSlash } from '@core/helpers/link';

import { SocialIcon } from '@models/social-icon';
import { Graphql } from '@models/graphql';
import { Office } from '@models/office';
import { MenuLink } from '@models/menu-link';

import allDataFooter from './default-query';

import './footer.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { globalSliderSettings } = require('@config/slider');

const footerData = {
  logo: 'logo-white-rootstack.png',
  socialIconList: [
    {
      icon: 'facebook-white.png',
      link: 'https://www.facebook.com/rootstack/',
      label: 'Facebook: @Rootstack'
    },
    {
      icon: 'linkedin-white.png',
      link: 'https://www.linkedin.com/company/rootstack',
      label: 'Linkedin: @Rootstack'
    },
    {
      icon: 'twitter-white.png',
      link: 'https://twitter.com/rootstack',
      label: 'Twitter: @Rootstack'
    },
    {
      icon: 'instagram-white.png',
      link: 'https://www.instagram.com/rootstack',
      label: 'Instagram: @Rootstack'
    },
    {
      icon: 'youtube-white.png',
      link: 'https://www.youtube.com/channel/UCVfAtNwOqp9BK0RJfcl5yeA',
      label: 'Youtube - Rootstack'
    }
  ]
};

export type FooterProps = {
  logo: string;
  backgroundColor: object;
  socialIconList: SocialIcon[];
};

export default function Footer(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const isMobileL = useMobileL();
  const data = allDataFooter();
  const offices = translateContent(
    data?.offices?.edges ?? [],
    i18n.language
  ).map((office: Graphql) => office.node) as Office[];

  return (
    <footer className="footer">
      <Container className="container-fluid text-md-left pt-5">
        <Menu nodes={data?.mainMenu?.nodes || []} language={i18n.language} />
        <Banners />
        <Offices offices={offices} />
        {isMobileL && (
          <div className="mt-3 mb-4">
            <h4 className="text-uppercase text-tangerine mb-4">
              {t('Follow Us')}
            </h4>
            <div className="d-flex">
              {footerData.socialIconList.map((socialIcon) => (
                <a
                  target="_blank"
                  className="footer-link-social-icon mr-3"
                  key={`${socialIcon.link}`}
                  href={`${socialIcon.link}`}
                  rel="noreferrer"
                >
                  <span className="text-hide">{`${socialIcon.label}`}</span>
                  <ImageProvider
                    className="rounded"
                    fluid
                    fileName={socialIcon.icon}
                    alt={`${socialIcon.label}`}
                    width="32"
                    height="32"
                  />
                </a>
              ))}
            </div>
          </div>
        )}
      </Container>
      <div className="copyright mt-4 py-4 px-3">
        <div className="text-uppercase footer-text text-center m-0">
          {t('Copyright')}
        </div>
      </div>
    </footer>
  );
}

type MenuProps = {
  nodes: MenuLink[];
  language: string;
};

function Menu({ nodes, language }: MenuProps): React.ReactElement {
  const { t } = useTranslation();
  const isMobileL = useMobileL();
  const mainMenu = buildMenu(nodes, language) || [];

  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  const toggleMenu = (menuTitle: string) => {
    setExpandedMenu((prevMenu) => (prevMenu === menuTitle ? null : menuTitle));
  };

  const socialIcons = footerData.socialIconList.map((socialIcon) => (
    <a
      target="_blank"
      className="footer-link-social-icon mr-3"
      key={`${socialIcon.link}`}
      href={`${socialIcon.link}`}
      rel="noreferrer"
    >
      <span className="text-hide">{`${socialIcon.label}`}</span>
      <ImageProvider
        className="rounded"
        fluid
        fileName={socialIcon.icon}
        alt={`${socialIcon.label}`}
        width="32"
        height="32"
      />
    </a>
  ));

  const renderItems = (list: MenuLink[] | undefined) =>
    list?.map((item: MenuLink) => (
      <li key={`${item.drupalId}`} className="mb-2">
        <GatsbyLink
          to={removeTrailingSlash(item?.link?.alias!)}
          className="text-white text-decoration-none mb-4"
        >
          {item.title}
        </GatsbyLink>
      </li>
    ));

  return (
    <div className="d-md-flex container-menu justify-content-between">
      <div className="col-12 col-lg-3 mb-4">
        <div className="mb-4">
          <ImageProvider
            fileName={footerData.logo}
            className="footer-main-logo"
            width="180"
            height="50"
            fluid
          />
          {!isMobileL && (
            <p className="mt-4 mr-4 text-white">
              {t(
                'We are Rootstack, a web, mobile and automation solutions agency from Panama with global clients'
              )}
            </p>
          )}
        </div>
        {!isMobileL && (
          <div className="mt-3 mb-4">
            <h4 className="text-uppercase text-tangerine mb-4">
              {t('Follow Us')}
            </h4>
            <div className="d-flex">{socialIcons}</div>
          </div>
        )}
      </div>
      <div className="d-md-flex justify-content-between">
      {mainMenu.map((menu) => (
        <div
          key={menu.drupalId}
          className="mb-md-0 mb-3 col-12 col-md-6 col-lg-2"
        >
          <div className={!isMobileL ? 'pb-4' : ''}>
            {isMobileL ? (
              <div className="text-uppercase text-white font-weight-bolder d-flex align-items-center justify-content-between cursor-pointer">
                <span>{menu.title}</span>
                <span onClick={() => toggleMenu(menu.title!)}>
                  <FontAwesomeIcon
                    icon={
                      expandedMenu === menu.title
                        ? (faAngleDown as IconProp)
                        : (faAngleRight as IconProp)
                    }
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      marginRight: '1rem'
                    }}
                  />
                </span>
              </div>
            ) : (
              <h5 className="text-uppercase text-white">{menu.title}</h5>
            )}
          </div>
          {isMobileL && expandedMenu === menu.title && (
            <ul className="list-unstyled font-weight-lighter">{renderItems(menu.children)}</ul>
          )}

          {!isMobileL && (
            <ul className="list-unstyled">{renderItems(menu.children)}</ul>
          )}
        </div>
      ))}
      </div>
    </div>
  );
}

function Banners() {
  const { t } = useTranslation();
  const isMobileL = useMobileL();

  if (isMobileL) {
    return (
      <Slider {...globalSliderSettings} className="container-banners mt-4">
        <div>
          <Banner
            title={t('See all the services we have')}
            subtitle={t(
              'We develop software solutions tailored to the needs of your business'
            )}
            label={t('See Services')}
            path={t('Path services page')}
            backgroundImage="cta-footer.png"
          />
        </div>
        <div>
          <Banner
            title={t('Join Our Team')}
            subtitle={t(
              'We are a team that works with passion, determination, and innovation'
            )}
            label={t('See Careers')}
            path={t('Path careers page')}
            backgroundImage="cta-join-our-team.png"
          />
        </div>
      </Slider>
    );
  }

  return (
    <Row className="container-banners mt-4">
      <Col sm={12} md={12} lg={6}>
        <Banner
          title={t('See all the services we have')}
          subtitle={t(
            'We develop software solutions tailored to the needs of your business'
          )}
          label={t('See Services')}
          path={t('Path services page')}
          backgroundImage="cta-footer.png"
        />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <Banner
          title={t('Join Our Team')}
          subtitle={t(
            'We are a team that works with passion, determination, and innovation'
          )}
          label={t('See Careers')}
          path={t('Path careers page')}
          backgroundImage="cta-join-our-team.png"
        />
      </Col>
    </Row>
  );
}

function Offices({ offices }: { offices: Office[] }): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Row className="container-offices mt-4">
      <Col className="col-12">
        <div className="mt-3 mb-2">
          <h4 className="text-uppercase text-tangerine">
            {t('Our Offices')}
          </h4>
        </div>
      </Col>
      {offices.map((office: Office) => (
        <Col key={office.id} className="mb-md-0 my-3" sm={12} md={12} lg={4}>
          <div className="d-flex flex-column h-100">
            <h5 className="text-uppercase text-white mb-3">{office.title}</h5>
            {office.body?.processed && (
              <div className="mb-2 text-white">
                <DomPurify text={office.body?.processed} />
              </div>
            )}
            {office.phone && (
              <ul className="list-unstyled mt-auto">
                <li className="text-white">{office.phone}</li>
              </ul>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
}

type BannerProps = {
  title: string;
  subtitle: string;
  label: string;
  path: string;
  backgroundImage: string;
};

function Banner({
  title,
  subtitle,
  label,
  path,
  backgroundImage
}: BannerProps): React.ReactElement {
  const image = require(`@assets/images/${backgroundImage}`);
  const isMobileL = useMobileL();

  const styleBackgroundCard = {
    backgroundImage: isMobileL ? 'none' : `url(${image.default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: isMobileL ? '#FFFFFF' : 'transparent',
    padding: '1.5rem'
  };

  return (
    <div
      style={styleBackgroundCard}
      className={`d-flex banner-cta align-items-center ${
        isMobileL ? 'custom-rounded' : 'rounded'
      }`}
    >
      <div className="text-light">
        <h5 className={`${isMobileL ? 'custom-text-color' : 'text-white'}`}>
          {title}
        </h5>
        <div
          className={`subtitle small ${
            isMobileL ? 'custom-text-color' : 'text-white'
          }`}
        >
          {subtitle}
        </div>
      </div>
      <div className="text-nowrap ml-auto">
        <GatsbyLink to={removeTrailingSlash(path)}>
          <Button variant="primary">{label}</Button>
        </GatsbyLink>
      </div>
    </div>
  );
}
