import { useStaticQuery, graphql } from 'gatsby';

export default function allDataFloatBanner() {
  const blocks = useStaticQuery(graphql`
    query allDataFloatBanner {
      banners: allBlockContentBannerBottom(
        sort: { order: DESC, fields: changed }
        filter: { field_enabled: { eq: true } }
      ) {
        edges {
          node {
            id
            bgcolor
            body {
              processed
            }
            langcode
            text_alignment
            text_color
            title
            title_color
            content_type
            cta {
              title
              alias: uri_alias
              uri
            }
            relationships {
              countries {
                name
                drupal_id
              }
            }
          }
        }
      }
    }
  `);

  return blocks;
}
