import { Graphql } from '@models/graphql';

const { languages } = require('@config/languages');

export default function translateContent(
  nodes: Graphql[],
  language: string = process.env.GATSBY_DEFAULT_LANG || 'en'
): Graphql[] {
  return nodes.filter((item: Graphql) => {
    const { node } = item;

    if (languages.indexOf(node?.langcode) < 0) {
      return true;
    }

    return node?.langcode === language;
  });
}
