import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';

import translateContent from '@core/helpers/translation';
import { isBrowser } from '@core/helpers/ssr';
import { slug } from '@core/helpers/strings';
import VideoItem from '@core/layout/navigation-bar/mega-menu/items/resources/video-item';

import { Graphql } from '@shared/models/graphql';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  menuLink: MenuLink
};

export default function VideoLinkItem({ menuLink }: LinkItemProps): ReactElement {
  const { t, i18n } = useTranslation();
  let decodedHash = '';

  if (isBrowser) {
    decodedHash = decodeURIComponent(slug(window.location.hash));
  }

  const data = VideoQuery();
  const videos = translateContent(data?.videos?.edges ?? [], i18n.language);

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t('Mega Menu Videos overview')}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <Row className="videos-container">
        {videos.slice(0, 3).map((video: Graphql) => (
          <div className="col-4 px-0" key={video?.node?.id}>
            <VideoItem
              node={video.node as Graphql}
              open={decodedHash === slug(video?.node?.title as string)}
              menuLink={menuLink}
            />
          </div>
        ))}
      </Row>
    </div>
  );
}

function VideoQuery() {
  const nodes = useStaticQuery(graphql`
  query VideoQuery {
    videos: allNodeVideo(limit: 3, sort: {fields: created, order: DESC}) {
      edges {
        node {
          id
          title
          langcode
          link: field_external_link {
            uri
          }
          relationships {
            media: field_video {
              name
              relationships {
                file: field_media_video_file {
                  src: localFile {
                    publicURL
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `);

  return nodes;
}
