import React, { ReactElement } from 'react';

import HowItWorks from '@core/layout/navigation-bar/mega-menu/items/service/how-it-works';
import BlogLinkItem from '@core/layout/navigation-bar/mega-menu/items/resources/blog-link-item';
import WhitepaperLinkItem from '@core/layout/navigation-bar/mega-menu/items/resources/whitepaper-link-item';
import VideoLinkItem from '@core/layout/navigation-bar/mega-menu/items/resources/video-link-item';
import SolutionsLinkItem from '@core/layout/navigation-bar/mega-menu/items/solutions/solutions-link-item';
import PartnershipsLinkItem from '@core/layout/navigation-bar/mega-menu/items/company/partnerships-link-item';
import LearningLinkItem from '@core/layout/navigation-bar/mega-menu/items/resources/learning-link-item';
import SuccessStoriesLinkItem from '@core/layout/navigation-bar/mega-menu/items/work/success-stories-link-item';
import ClientsLinkItem from '@core/layout/navigation-bar/mega-menu/items/work/clients-link-item';
import ReviewsLinkItem from '@core/layout/navigation-bar/mega-menu/items/work/reviews-link-item';
import MethodologyLinkItem from '@core/layout/navigation-bar/mega-menu/items/resources/methodology-link-item';
import AteamLinkItem from '@core/layout/navigation-bar/mega-menu/items/company/ateam-link-item';
import AboutUsLinkItem from '@core/layout/navigation-bar/mega-menu/items/company/about-us-link-item';
import TalentLinkItem from '@core/layout/navigation-bar/mega-menu/items/company/talent-link-item';
import TechnologyLinkItem from '@core/layout/navigation-bar/mega-menu/items/solutions/technology-link-item';
import AreaExpertiseLinkItem from '@core/layout/navigation-bar/mega-menu/items/solutions/area-expertise-link-item';
import RecognitionLinkItem from '@core/layout/navigation-bar/mega-menu/items/company/recognitions-link-item';

import { MenuLink } from '@models/menu-link';

const components: { [key: string]: Function } = {
  mega_menu_content__see_all: HowItWorks,
  mega_menu_content__ver_todos: HowItWorks,
  mega_menu_content__it_staff_augmentation: HowItWorks,
  mega_menu_content__it_staff_augmentation_servicios: HowItWorks,
  mega_menu_content__managed_teams: HowItWorks,
  mega_menu_content__equipos_manejados: HowItWorks,
  mega_menu_content__projectbased_model_services: HowItWorks,
  mega_menu_content__servicios_de_modelo_basado_en_proyectos: HowItWorks,
  mega_menu_content__managed_services: HowItWorks,
  mega_menu_content__servicios_manejados: HowItWorks,
  mega_menu_content__success_stories: SuccessStoriesLinkItem,
  mega_menu_content__casos_de_exito: SuccessStoriesLinkItem,
  mega_menu_content__clients: ClientsLinkItem,
  mega_menu_content__clientes: ClientsLinkItem,
  mega_menu_content__reviews: ReviewsLinkItem,
  mega_menu_content__resenas: ReviewsLinkItem,
  mega_menu_content__solution_discovery: HowItWorks,
  mega_menu_content__solution_discovery_servicios: HowItWorks,
  mega_menu_content__blog: BlogLinkItem,
  mega_menu_content__whitepapers: WhitepaperLinkItem,
  mega_menu_content__learning: LearningLinkItem,
  mega_menu_content__aprendizaje: LearningLinkItem,
  mega_menu_content__methodologies: MethodologyLinkItem,
  mega_menu_content__metodologias: MethodologyLinkItem,
  mega_menu_content__videos: VideoLinkItem,
  mega_menu_content__industries: SolutionsLinkItem,
  mega_menu_content__industrias: SolutionsLinkItem,
  mega_menu_content__solutions: SolutionsLinkItem,
  mega_menu_content__soluciones: SolutionsLinkItem,
  mega_menu_content__technologies: TechnologyLinkItem,
  mega_menu_content__tecnologias: TechnologyLinkItem,
  mega_menu_content__areas_de_especializacion: AreaExpertiseLinkItem,
  mega_menu_content__areas_of_expertise: AreaExpertiseLinkItem,
  mega_menu_content__about_us: AboutUsLinkItem,
  mega_menu_content__acerca_de_nosotros: AboutUsLinkItem,
  mega_menu_content__partnerships: PartnershipsLinkItem,
  mega_menu_content__alianzas: PartnershipsLinkItem,
  mega_menu_content__a_team: AteamLinkItem,
  mega_menu_content__equipo_a: AteamLinkItem,
  mega_menu_content__talent: TalentLinkItem,
  mega_menu_content__talento: TalentLinkItem,
  mega_menu_content__reconocimientos: RecognitionLinkItem,
  mega_menu_content__recognitions_and_honours: RecognitionLinkItem
};

type ContentProps = {
  machineName: string;
  menuLink: MenuLink|undefined;
};

export default function ContentComponent({ machineName, menuLink }: ContentProps): ReactElement {
  const componentKey = `mega_menu_content__${machineName?.trim()?.toLowerCase()
    .normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, '')
    .replace(/ /g, '_')}`;

  if (components[componentKey]) {
    const Component = components[componentKey];
    return <Component key={`${machineName}`} machineName={machineName} menuLink={menuLink} />;
  }

  return <></>;
}
