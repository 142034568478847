import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';

import {
  getPathFromInternalUrl,
  removeTrailingSlash
} from '@core/helpers/link';
import CallUs from '@core/layout/top-bar/call-us';
import allTopBarMenu from '@core/layout/top-bar/default-query';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './contact-bar.scss';

export default function ModernNavigationBarContact(): React.ReactElement {
  const { i18n } = useTranslation();
  const links = allTopBarMenu();

  const isContact = (link: MenuLink) => {
    const { link: linkItem } = link || {};
    const path = linkItem?.alias || linkItem?.uri || '';
    return path.includes('contact');
  };

  return (
    <Navbar collapseOnSelect className="modern-contact-bar background-white p-0 sticky">
      <Container>
        <Navbar.Brand href={`/${i18n.language}`}>
          <ImageProvider
            className="h-100"
            fluid
            fileName="rootstack-logo-modern.svg"
            alt="logo"
            lazy={false}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <ImageProvider
              className="mr-2 d-none d-sm-block"
              fluid
              fileName="usa.svg"
              alt="logo"
            />
          </Nav>
          <Nav>
            {links.map(
              (link: MenuLink) =>
                isContact(link) && (
                  <GatsbyLink
                    key={link.drupalId}
                    className="small ml-2 persists-active p-3 btn background-modern-orange my-1 text-white mr-2"
                    to={
                      removeTrailingSlash(link?.link?.alias!) ||
                      getPathFromInternalUrl(link?.link?.uri || '')
                    }
                  >
                    {link.title}
                  </GatsbyLink>
                )
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
