import React, { useMemo, useState } from 'react';

import translateContent from '@core/helpers/translation';
import { includes } from '@core/helpers/strings';
import allNodeSearchQuery from '@modules/search/default-query';
import { Graphql } from '@models/graphql';

const SearchContext = React.createContext({
  showSearch: false,
  setShowSearch: (_flag: boolean) => {},
  handleTextSearch: (
    _language: string = '',
    _text: string = '',
    _type: string = '',
    _tag: string = '',
    _date: string|null = null
  ) => [],
  contentTags: [],
  contentTypes: []
});

export default function SearchProvider(props: object): React.ReactElement {
  const [showSearch, setShowSearch] = useState(false);
  const {
    allNodeBlog, allNodeWhitepaper, allNodeSuccessStory,
    allNodeIndustry, allNodeSolution, allNodeCareer,
    allTaxonomyTermTags, allNodeTypeNodeType
  } = allNodeSearchQuery();
  const contentTypes = allNodeTypeNodeType?.edges || [];
  const contentTags = allTaxonomyTermTags.edges;

  const handleTextSearch = (language = '', text = '', contentType = '', tag = '', date: null) => {
    const translatedNodes = translateContent(
      [].concat(
        allNodeBlog.edges,
        allNodeWhitepaper.edges,
        allNodeSuccessStory.edges,
        allNodeIndustry.edges,
        allNodeSolution.edges,
        allNodeCareer.edges
      ) ?? [],
      language
    )
      .map((item: Graphql) => (item.node))
      .sort((a, b) => {
        if (!a?.title || !b?.title) {
          return 1;
        }

        return a.title > b.title ? 1 : -1;
      })
      .filter(
        (node) => {
          let response = true;

          if (text.length > 0) {
            response = includes(node?.title || '', text) || includes(node?.wysiwyg?.summary || '', text);
          }

          if (contentType.length > 0) {
            response = response
            && includes(node?.type || '', contentType);
          }

          if (tag.length > 0) {
            const fieldTags = node?.relationships?.tags;

            if (fieldTags) {
              let containTag = false;

              fieldTags.forEach((item) => {
                if (item.drupal_internal__tid === parseInt(tag, 10)) {
                  containTag = true;
                }
              });

              response = response && containTag;
            }
          }

          if (date) {
            const parsedDate = new Date(date)
            const year = parsedDate.getFullYear()
            const month = parsedDate.getMonth()
            const day = parsedDate.getDate()
            const nodeDate = new Date(`${node?.created}`);
            response = response
              && (nodeDate.getFullYear() === year
              && nodeDate.getMonth() === month
              && nodeDate.getDate() === day);
          }

          return response;
        }
      );

    return translatedNodes as never[];
  };

  const value = useMemo(() => ({
    showSearch,
    setShowSearch,
    handleTextSearch,
    contentTags,
    contentTypes
  }), [showSearch]);

  return (
    // @ts-ignore
    <SearchContext.Provider value={value} {...props} />
  );
}

export function useSearch() {
  return React.useContext(SearchContext);
}
