import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';

import { MenuLink } from '@models/menu-link';

import './styles.scss';

type LinkItemProps = {
  menuLink: MenuLink;
};

export default function RecognitionLinkItem({
  menuLink,
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <p className="font-weight-normal text-secondary">
        {t('Mega Menu Recognition overview')}
      </p>
      <div className="row company-content">
        <div className="col">
          <DomPurify text={t('Mega Menu Recognition Content')} />
        </div>
        <div className="d-flex align-items-end align-bottom w-25">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left"
            to={menuLink?.link?.alias || ''}
          >
            {t('Mega Menu Recognition Link')}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
