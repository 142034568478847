import React from 'react';
import { useTranslation } from 'react-i18next';
import { CookieNotice } from 'gatsby-cookie-notice';

import './cookie.scss';

export default function Cookie(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <CookieNotice
      buttonWrapperClasses="button-wrapper"
      personalizeButtonClasses="personalize-button"
      personalizeButtonText={t('Cookie personalize button')}
      backgroundWrapperClasses="bg-cookie fixed-bottom"
      declineButtonText={t('Cookie decline button')}
      acceptButtonText={t('Cookie accept button')}
      acceptButtonClasses="btn btn-primary"
      declineButtonClasses="btn btn-secondary"
      cookies={[
        {
          name: 'necessary',
          editable: false,
          default: true,
          title: t('Essential title Cookie'),
          text: t('Essential body Cookie')
        },
      ]}
    >
      <p className="cookie-body">{t('Cookie body')}</p>
    </CookieNotice>
  );
}
