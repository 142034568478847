import { MenuLink } from '@models/menu-link';

export default function buildMenu(links: MenuLink[], language: string): MenuLink[] {
  if (!links) {
    return [];
  }

  const enabledLinks = links
    .filter((link: MenuLink) => !!link.enabled && link.langcode === language)
    .sort((currentLink, nextLink) => currentLink?.weight - nextLink?.weight)
    .map((item: MenuLink) => {
      if (item.link?.alias?.substring(0, 3) !== '/es') {
        return item
      }

      return {
        ...item,
        link: {
          ...item.link,
          alias: item.link?.alias?.replace('/es', '')
        }
      }
    });

  const parentLinks = enabledLinks.map((link: MenuLink) => (
    {
      ...link,
      children: enabledLinks.filter((_link: MenuLink) => {
        if (!_link.drupalParentMenuItem) {
          return false;
        }

        return _link.drupalParentMenuItem.includes(link?.drupalId || '');
      })
    }
  ));

  return parentLinks.filter((link: MenuLink) => parentLinks
    .filter((_link: MenuLink) => _link?.children?.find((child: MenuLink) => child.drupalId === link.drupalId)).length === 0);
}
