import { CacheProvider } from '@emotion/react'
// eslint-disable-next-line import/prefer-default-export
export { wrapPageElement } from './gatsby-shared';

import { myCache } from './create-emotion-cache'

let nextRoute = '';

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={myCache}>{element}</CacheProvider>
)