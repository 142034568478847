import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';

import buildMenu from '@core/helpers/menu';

export default function allTopBarMenu() {
  const { i18n } = useTranslation();

  const { topBarMenu } = useStaticQuery(graphql`
    query allTopBarMenu {
      topBarMenu: allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: "topbar"}}) {
        nodes {
          menuName: menu_name
          drupalId: drupal_id
          drupalParentMenuItem: drupal_parent_menu_item
          title
          langcode
          enabled
          weight
          link {
            alias: uri_alias
            uri
          }
        }
      }
    }
  `);

  return buildMenu(topBarMenu.nodes, i18n.language) || [];
}
