import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function AreaExpertiseLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  const { expertiseAreas } = ExpertiseAreasQuery();
  const { t, i18n } = useTranslation();
  const items = translateContent(expertiseAreas?.edges ?? [], i18n.language);

  return megaMenuStyle === 'modern' ? (
    <ModernAreaExpertiseLinkItem items={items} />
  ) : (
    <DefaulAreaExpertiseLinkItem items={items} menuLink={menuLink} machineName={machineName} />
  );
}

function ModernAreaExpertiseLinkItem({ items }: { items: any[] }): ReactElement {
  return (
    <div className="d-flex flex-column mt-3">
      {items.slice(0, 8).map(({ node }) => (
        <div className="d-flex flex-row align-items-center mb-3" key={node?.id}>
          <ImageProvider
            fluid={false}
            fileName={node?.relationships?.image?.name}
            alt={node?.relationships?.image?.attributes?.alt}
            title={node?.relationships?.image?.attributes?.title}
            height="35"
            width="35"
          />
          <GatsbyLink
            to={node?.link?.uri || ''}
            className="card-link text-marine"
          >
            <h6 className="m-0 ml-2 pl-1">{node?.title}</h6>
          </GatsbyLink>
        </div>
      ))}
    </div>
  );
}

function DefaulAreaExpertiseLinkItem({
  items,
  menuLink,
  machineName
}: {
  items: any[];
  menuLink: MenuLink;
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName.trim()} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.slice(0, 6).map(({ node }) => (
              <div className="col-6 mb-2" key={node?.id}>
                <div className="row mt-2">
                  <div className="col-2 pb-1">
                    <ImageProvider
                      fileName={node?.relationships?.image?.name}
                      alt={node?.relationships?.image?.attributes?.alt}
                      title={node?.relationships?.image?.attributes?.title}
                      height="48"
                    />
                  </div>
                  <div className="col pt-1">
                    <GatsbyLink
                      to={node?.link?.uri || ''}
                      className="card-link text-dark-indigo"
                    >
                      {node?.title}
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function ExpertiseAreasQuery() {
  const nodes = useStaticQuery(graphql`
    query ExpertiseAreasQuery {
      expertiseAreas: allNodeExpertiseArea(
        filter: { field_weight: { gte: 10 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
            }
            relationships {
              image: field_menu_icon {
                name

                attributes: field_media_image {
                  alt
                  title
                }
                relationships {
                  field_media_image {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
