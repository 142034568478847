import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';

import buildMenu from '@core/helpers/menu';

export default function allMainMegaMenu(isMobile: boolean) {
  const { i18n } = useTranslation();

  const { mainMenu, topBarMenu } = useStaticQuery(graphql`
    query allMainMegaMenu {
      mainMenu: allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: "navbar"}}) {
        nodes {
          menuName: menu_name
          drupalId: drupal_id
          drupalParentMenuItem: drupal_parent_menu_item
          title
          langcode
          enabled
          weight
          link {
            alias: uri_alias
            uri
          }
        }
      }
      topBarMenu: allMenuLinkContentMenuLinkContent(filter: { menu_name: { eq: "topbar" } }) {
        nodes {
          menuName: menu_name
          drupalId: drupal_id
          drupalParentMenuItem: drupal_parent_menu_item
          title
          langcode
          enabled
          weight
          link {
            alias: uri_alias
            uri
          }
        }
      }
    }
  `);

  const mergedMenuData = !isMobile ? mainMenu.nodes : [...mainMenu.nodes, ...topBarMenu.nodes]

  return buildMenu(mergedMenuData, i18n.language) || [];
}
