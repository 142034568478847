import { Country } from '@models/country';

export const ipApiBaseUrl = process.env.GATSBY_IP_API_BASE_URL || '';

export const defaultCountryCode = process.env.GATSBY_DEFAULT_COUNTRY_CODE || '';

export const defaultContext = {
  city: '',
  country: '',
  countryCode: '',
  lat: 0,
  lon: 0,
  region: '',
  regionName: '',
  status: '',
  timezone: ''
} as Country;
