import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { slug } from '@core/helpers/strings';
import { useIndexes } from '@core/providers/index';

import { Graphql } from '@shared/models/graphql';

const pathFilesRegExp = /\/sites\/default\/files\//gi;
const pathFiles = `${process.env.GATSBY_BASE_URL_API}/sites/default/files/`;

type DomPurifyProps = {
  text: String;
  includeIndex?: Boolean;
  showIndex?: Boolean;
};

DomPurify.defaultProps = {
  includeIndex: false,
  showIndex: true
};

export default function DomPurify({
  text,
  includeIndex, showIndex
}: DomPurifyProps): React.ReactElement {
  if (!text) {
    return <></>;
  }

  const { t } = useTranslation();
  const [html, setHtml] = useState(text.replace(pathFilesRegExp, pathFiles));
  const [activeIndex, setActiveIndex] = useState(0);
  const { indexes, setIndexes } = useIndexes();

  useEffect(() => {
    if (html && includeIndex) {
      const parser = new DOMParser();
      const element = parser.parseFromString(html, 'text/html');
      const headings = ['h2', 'h3', 'h4', 'h5'];
      let headingList: Graphql[] = [];

      for (let index = 0; index < headings.length; index += 1) {
        headingList = headingList
          .concat((Array.from(element.getElementsByTagName(headings[index].toString()))));
      }

      for (let index = 0; index < headingList.length; index += 1) {
        headingList[index].id = slug(headingList[index]?.innerText || '');
      }

      setIndexes(headingList);
      setHtml(element.body.outerHTML);
    }
  }, [html]);

  const onIndexLinkClickHandler = (event: React.MouseEvent, element: Graphql, index: number) => {
    event.preventDefault();
    setActiveIndex(index);

    scrollToElement(element.id || '');
  };

  const scrollToElement = (elementId: string) => {
    document?.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      {includeIndex && showIndex && indexes.length > 0 && (
        <div className="wysiwyg-indexes">
          <h5 className="text-left text-nice-blue mt-4">{ t('index') }</h5>
          <div className="d-flex flex-wrap align-items-center mb-4">
            {
              indexes?.map((item, index) => (
                <a
                  href={`#${slug(item.innerText || '')}`}
                  key={slug(item.innerText || '')}
                  className={`wysiwyg-indexes__link cursor-pointer font-weight-bold rounded mr-3 mt-2 ${index === activeIndex ? 'active' : ''}`}
                  onClick={(event) => onIndexLinkClickHandler(event, item, index)}
                >
                  {item.innerText}
                </a>
              ))
            }
          </div>
        </div>
      )}
      <div className="wysiwyg-purified" dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
}
